const texteditors = document.querySelectorAll(".js-c-form-input--markdown-editor")

texteditors.forEach(initMdEditor)

function initMdEditor(editor) {
  if (!editor) return

  const textarea = editor.querySelector("textarea")  
  if (!textarea) return

  textarea.addEventListener("keydown", (event) => handleKeyDown(event, textarea))

  const actions = {
    ".js-markdown-editor__btn--ul": () => insertListItem(textarea, "- "),
    ".js-markdown-editor__btn--ol": () => insertListItem(textarea, "1. "),
    ".js-markdown-editor__btn--bold": () => wrapSelection(textarea, "**"),
    ".js-markdown-editor__btn--italic": () => wrapSelection(textarea, "_"),
    ".js-markdown-editor__btn--h2": () => insertHeading(textarea, "## "),
    ".js-markdown-editor__btn--h3": () => insertHeading(textarea, "### "),
    ".js-markdown-editor__btn--h4": () => insertHeading(textarea, "#### "),
    ".js-markdown-editor__btn--link": () => insertLink(textarea)
  }

  Object.entries(actions).forEach(([selector, action]) => {
    const button = editor.querySelector(selector)
    if (button) {
      button.addEventListener("click", action)
    }
  })
}

function handleKeyDown(event, textarea) {
  const shortcuts = {
    "b": () => wrapSelection(textarea, "**"),
    "i": () => wrapSelection(textarea, "_"),
    "2": () => insertHeading(textarea, "## "),
    "3": () => insertHeading(textarea, "### "),
    "4": () => insertHeading(textarea, "#### "),
    "k": () => insertLink(textarea)
  }

  if (event.key === "Enter") {
    handleListInsertion(event, textarea)
  } else if ((event.metaKey || event.ctrlKey) && shortcuts[event.key]) {
    event.preventDefault()
    shortcuts[event.key]()
  }
}

function handleListInsertion(event, textarea) {
  const cursorPos = textarea.selectionStart
  const text = textarea.value
  const beforeCursor = text.substring(0, cursorPos)
  const afterCursor = text.substring(cursorPos)

  const lines = beforeCursor.split("\n")
  const currentLine = lines[lines.length - 1]

  const unorderedMatch = currentLine.match(/^(\s*)[-*]\s+/)
  const orderedMatch = currentLine.match(/^(\s*)(\d+)\.\s+/)

  if (unorderedMatch) {
    event.preventDefault()
    processUnorderedList(textarea, cursorPos, beforeCursor, afterCursor, unorderedMatch[1])
  } else if (orderedMatch) {
    event.preventDefault()
    processOrderedList(textarea, cursorPos, beforeCursor, afterCursor, orderedMatch[1], orderedMatch[2])
  }
}

function processUnorderedList(textarea, cursorPos, beforeCursor, afterCursor, indent) {
  const trimmedBefore = beforeCursor.trim()

  if (trimmedBefore === "-") {
    // Remove the hyphen if pressing Enter on an empty list item
    const newText = beforeCursor.replace(/-\s*$/, "") + afterCursor
    updateTextarea(textarea, newText, cursorPos - 2) 
  } else {
    const newText = beforeCursor + `\n${indent}- ` + afterCursor
    updateTextarea(textarea, newText, cursorPos + indent.length + 3) // Move cursor after "- "
  }
}

function processOrderedList(textarea, cursorPos, beforeCursor, afterCursor, indent, currentNumber) {
  const newText = beforeCursor.trim() === `${currentNumber}.` 
    ? beforeCursor.replace(/\d+\.\s*$/, "") + afterCursor 
    : beforeCursor + `\n${indent}${parseInt(currentNumber, 10) + 1}. ` + afterCursor

  updateTextarea(textarea, newText, cursorPos + newText.length - textarea.value.length)
}

function insertListItem(textarea, prefix) {
  const cursorPos = textarea.selectionStart
  const beforeCursor = textarea.value.substring(0, cursorPos)
  const afterCursor = textarea.value.substring(cursorPos)

  const newText = beforeCursor + `\n${prefix}` + afterCursor
  const newCursorPos = cursorPos + prefix.length + 1 // Cursor moves right after prefix

  updateTextarea(textarea, newText, newCursorPos)
}

function insertHeading(textarea, prefix) {
  const cursorPos = textarea.selectionStart
  const text = textarea.value
  const lineStart = text.lastIndexOf("\n", cursorPos - 1) + 1
  const line = text.substring(lineStart, cursorPos)

  const newText = /^#+\s/.test(line)
    ? text.substring(0, lineStart) + line.replace(/^#+\s/, "") + text.substring(cursorPos)
    : text.substring(0, lineStart) + prefix + text.substring(lineStart)

  const newCursorPos = lineStart + prefix.length // Cursor moves after the heading prefix

  updateTextarea(textarea, newText, newCursorPos)
}

function insertLink(textarea) {
  const { selectionStart: start, selectionEnd: end, value: text } = textarea
  const selectedText = text.substring(start, end) || "link"

  const newText = text.substring(0, start) + `[${selectedText}](url)` + text.substring(end)
  const cursorPosition = start + selectedText.length + 3 // Cursor moves inside the URL part

  updateTextarea(textarea, newText, cursorPosition)
}

function wrapSelection(textarea, wrapper) {
  const { selectionStart: start, selectionEnd: end, value: text } = textarea
  const selectedText = text.substring(start, end)

  const newText = selectedText
    ? text.substring(0, start) + wrapper + selectedText + wrapper + text.substring(end)
    : text.substring(0, start) + wrapper + wrapper + text.substring(end)

  const newCursorPos = selectedText ? end + wrapper.length : start + wrapper.length // Cursor inside format

  updateTextarea(textarea, newText, newCursorPos)
}

function updateTextarea(textarea, newText, cursorPos) {
  textarea.value = newText
  textarea.setSelectionRange(cursorPos, cursorPos)
  textarea.focus()
}
