import { createAnalyticsGroup } from 'js/lib/analytics'

const container = document.querySelector('.js-c-pages-partner_edi')

if (container) {
  const links = container.querySelectorAll('a')
  const track = createAnalyticsGroup('edi--parnter-page')

  links.forEach(link => {
    const label = link.dataset.tracking || link.textContent.trim()

    link.addEventListener('click', () => {
      track('click', label)
    })
  })
}
