function toggle_visibility(el) {
  const control = el.getAttribute('aria-controls');
  const expanded = el.getAttribute('aria-expanded') === 'false';
  document.querySelector(`#${control}`).type = expanded ? 'text' : 'password';
  el.setAttribute('aria-expanded', String(expanded));
  el.textContent = expanded ? 'hide' : 'show';
  el.setAttribute('aria-label', expanded ? 'Hide password' : 'Show password');
}

document.querySelectorAll('.js-c-form-input--password-toggle').forEach((toggle) => {
  toggle.addEventListener('click', (event) => {
    event.preventDefault();
    toggle_visibility(event.target);
  });

  toggle.addEventListener('keydown', (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggle_visibility(event.target);
    }
  });
});