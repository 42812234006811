import { focusLock } from 'js/lib/focus-lock'

// TODO: state management for layered an nested UIs
// For example, when a dropdown is opened within a modal
// escape key, or click outside events should only close the open dropdown
// and not both the dropdown and modal

const UIModals = document.querySelectorAll('.js-c-ui-modal')
let previouslyFocusedElement

function initModal(modal) {
  if (!modal) return

  const modalId = modal.id
  const triggers = document.querySelectorAll(`[aria-controls="${modalId}"]`)
  const closeButtons = modal.querySelectorAll('.js-c-ui-modal--close')
  const modalInner = modal.querySelector('.js-c-ui-modal__inner')

  triggers.forEach(trigger => {
    trigger.addEventListener('click', () => openModal(modal, trigger, modalInner))
  })

  closeButtons.forEach(btn => {
    btn.addEventListener('click', () => closeModal(modal))
  })

  if (modalInner) {
    modal.addEventListener('click', () => 
      closeModal(modal))

    modalInner.addEventListener('click', (event) => {
      event.stopPropagation()
    })
  }
}

const openModal = (modal, trigger, modalInner) => {

  previouslyFocusedElement = document.activeElement
  const focusTargetId = trigger.getAttribute('data-focus')

  setTimeout(() => {
    modal.focus()

    if (focusTargetId) {
      const elementToFocus = document.getElementById(focusTargetId)
      if (elementToFocus) {
        elementToFocus.scrollIntoView({ block: 'center' })
      }
    } else {
      if (modalInner) {
        modalInner.scrollTop = 0
      }
    }
  }, 0)

  modal.setAttribute('data-state', 'open')
  modal.classList.remove('tw-hidden')
  document.body.classList.add('tw-overflow-hidden')
  document.addEventListener('keydown', (event) => handleKeydown(event, modal))
  document.addEventListener('keydown', (event) => focusLock(event, modal))
}

const closeModal = (modal) => {
  modal.setAttribute('data-state', 'close')
  modal.classList.add('tw-hidden')
  document.body.classList.remove('tw-overflow-hidden')

  document.removeEventListener('keydown', (event) => handleKeydown(event, modal))
  document.removeEventListener('keydown', (event) => focusLock(event, modal))

  if (previouslyFocusedElement) {
    previouslyFocusedElement.focus()
  }
}

const handleKeydown = (event, modal) => {
  if (event.key === 'Escape' && modal.getAttribute('data-state') === 'open') {
    if (modal.contains(document.activeElement)) {
      closeModal(modal)
    }
  }
}

UIModals.forEach((modal) => {
  initModal(modal)
})
