import createFormHelper from '../../../../form/helpers/form_helper'
import { clearBadgesAndUpdateParamsFor } from '../../../../form/input/multi_select_search/component'

const FORM_NAME = 'rms_pet_assignments_upsert_command'
const SPECIES_INPUT = `${FORM_NAME}[pet_attributes][species_id]`
const BIRTHDATE_INPUT = `${FORM_NAME}[pet_attributes][birthdate]`
const SENIOR_INPUT = `${FORM_NAME}[pet_attributes][senior]`
const BREED_INPUT = `${FORM_NAME}[pet_attributes][breeds]`
const MIX_INPUT = `${FORM_NAME}[pet_attributes][mix]`
const SIZE_INPUT = `${FORM_NAME}[pet_attributes][size]`
const VACCINATED_INPUT = `${FORM_NAME}[pet_attributes][vaccinated]`
const HWT_INPUT = `${FORM_NAME}[pet_attributes][heart_worm_treated]`
const WORMED_INPUT = `${FORM_NAME}[pet_attributes][wormed]`
const NCC_INPUT = `${FORM_NAME}[needs_constant_care]`
const COAT_INPUT = `${FORM_NAME}[pet_attributes][coat]`
const INDOOR_INPUT = `${FORM_NAME}[indoor_only]`

const PIC_ID_INPUT = `${FORM_NAME}[rehoming_pic_id]`

// TODO: keeping these around incase we want to add show/hide feature like in the listings form
// const SOURCE_ID_INPUT = `${FORM_NAME}[rehoming_source_id]` // unused
// const BREEDER_ID_INPUT = `${FORM_NAME}[rehoming_breeder_id]` // unused
// const REHOMING_ORG_ID_INPUT = `${FORM_NAME}[rehoming_organisation_id]` // unused

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('.js-rms_pet_form')
  if (!el) return
  const petForm = createFormHelper(el)
  let previousSpeciesValue = null

  const initialFormState = petForm.getState()
  previousSpeciesValue = initialFormState[SPECIES_INPUT].value
  handleSpeciesChange(initialFormState, true)
  handleSeniorField(initialFormState)

  petForm.onChange(formState => {
    handleSpeciesChangeIfNecessary(formState)
    handleSeniorField(formState)
  })

  const medicalIssuesField = document.querySelector('.js-has-medical-issues')
  const medicalNotesContainer = document.querySelector('.js-medical-notes-container')
  const medicalNotesField = document.querySelector('.js-medical-notes')

  if (medicalIssuesField) {
    medicalIssuesField.addEventListener('change', event => {
      if (event.target.value === 'true') {
        medicalNotesContainer.style.display = 'block'
        medicalNotesField.required = true
      } else {
        medicalNotesContainer.style.display = 'none'
        medicalNotesField.value = ''
        medicalNotesField.required = false
      }
    })
  }

  function handleSpeciesChangeIfNecessary(formState) {
    const newSpecies = formState[SPECIES_INPUT].value
    if (newSpecies !== previousSpeciesValue) {
      handleSpeciesChange(formState, false)
      previousSpeciesValue = newSpecies
    }
  }

  function handleSeniorField(formState) {
    const birthdate = new Date(formState[BIRTHDATE_INPUT].value)
    const minSeniorAge = formState[SENIOR_INPUT].dataAttr.minAge
    const currentDate = new Date()
    const timeDiff = currentDate.getTime() - birthdate.getTime()
    const petAge = Math.floor(timeDiff / (1000 * 3600 * 24 * 365.25))

    if (petAge >= minSeniorAge) {
      petForm.show(SENIOR_INPUT)
    } else {
      petForm.hide(SENIOR_INPUT)
    }
  }

  function handleSpeciesChange(formState, skipBadgeReset) {
    const species = formState[SPECIES_INPUT].value
    const cFormSelectSearch = document
      .querySelector(`[name="${BREED_INPUT}"]`)
      .closest('.js-c-form-multi-select-search')

    console.log(species)

    // Reset all fields.
    // If you add new fields to be dynamically changed below in the switch/case then add them here too
    petForm.hideAll([
      BREED_INPUT,
      MIX_INPUT,
      SIZE_INPUT,
      VACCINATED_INPUT,
      HWT_INPUT,
      WORMED_INPUT,
      NCC_INPUT,
      COAT_INPUT,
      INDOOR_INPUT,
      PIC_ID_INPUT,
    ])

    switch (species) {
      case '1': // Dog
        petForm.show(BREED_INPUT)
        petForm.show(MIX_INPUT)
        petForm.show(SIZE_INPUT)
        petForm.show(VACCINATED_INPUT)
        petForm.show(HWT_INPUT)
        petForm.show(WORMED_INPUT)
        petForm.show(NCC_INPUT)
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 1 }))
        }
        break
      case '2': // Cat
        petForm.show(BREED_INPUT)
        petForm.show(MIX_INPUT)
        petForm.show(COAT_INPUT)
        petForm.show(VACCINATED_INPUT)
        petForm.show(WORMED_INPUT)
        petForm.show(INDOOR_INPUT)
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 2 }))
        }
        break
      case '3': // Guinea Pig
        petForm.show(BREED_INPUT)
        petForm.show(MIX_INPUT)
        petForm.show(COAT_INPUT)
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 3 }))
        }
        break
      case '4': // Rabbit
        petForm.show(BREED_INPUT)
        petForm.show(MIX_INPUT)
        petForm.show(VACCINATED_INPUT)
        if (cFormSelectSearch) {
          cFormSelectSearch.setAttribute('data-query-params', JSON.stringify({ species_id: 4 }))
        }
        break
      default:
        // Pic number should only really show for cattle - but this is a little lazy way to get it working
        petForm.show(PIC_ID_INPUT)
        break
    }

    if (!skipBadgeReset) {
      clearBadgesAndUpdateParamsFor(petForm.getInputElement(BREED_INPUT), { species_id: species })
    }
  }
})
