import createFormHelper from 'components/form/helpers/form_helper'

function initialize() {
  const formEl = document.querySelector('.js-rms-forms-case-closures-default')
  if (!formEl) return

  const caseForm = createFormHelper(formEl)
  const initialFormState = caseForm.getState()

  handleClosureReasonChange(caseForm, initialFormState)

  caseForm.onChange(formState => {
    handleClosureReasonChange(caseForm, formState)
  })
}

function handleClosureReasonChange(caseForm, formState) {
  const closureReason = formState['rms_cases_close_command[closed_reason]'].value

  const petIneligableSections = document.querySelectorAll('.js-rms-case-closure-pet-reason')
  const petIneligableInputs = document.querySelectorAll('.js-rms-case-closure-pet-reason-input')

  console.log(petIneligableSections)

  switch (closureReason) {
    case 'pets_are_ineligible':
      petIneligableInputs.forEach(el => caseForm.show(el.name))
      petIneligableSections.forEach(el => {
        el.ariaHidden = false
        el.classList.remove('tw-hidden')
      })

      break
    default:
      petIneligableInputs.forEach(el => caseForm.hide(el.name))
      petIneligableSections.forEach(el => {
        el.ariaHidden = true
        el.classList.add('tw-hidden')
      })
      break
  }
}

initialize()
