import Bugsnag from '@bugsnag/js'

const denyList = [
  /securepubads/gm, // ads network
  /doubleclick/gm, // ads network
  /adservice\.google/gm, // ads network
  /googlesyndication/gm, // ads network
  /quantcast\.mgr\.consensu/gm, // ads network
  /btloader/gm, // ads network
  /angsrvr/gm, // ads network
  /global\.ib-ibi/gm, // ads network
  /cdn\.flashtalking\.com/gm, // ads network
  /cdn\.adnxs\.com/gm, // ads network
  /connect\.facebook\.net/gm, // ads network
  /cdn\.publift/gm,
  /cdn\.fuseplatform/gm,
  /cdn\.adnxs/gm,
  /unknown(.*)global code/gms, // ads code that is executing on the page in a script tag
  /loadScr(.*)eval/gms, // any ads that load and eval scripts
  /beacon-v2\.helpscout\.net/gm, // helpscout (ignore erros coming from them)
  /raw\.githubusercontent\.com/gm, // code executed from git hub (usually ads)
  /extractFormsAndFormElements/gm, // safari brwoser code that executes and is denied due to cors https://codereview.chromium.org/1335503002
  /unknown\:\d*\:\d*/, // ignore code that has no source - ie in a plain <script> tag or ads file
  /extractSchemaValuesFromSchemaOrg/gm, // ignore code that is trying to parse our site (ads, bad webcrawlers, etc)
]

if (PetRescue.env.environment !== 'development') {
  Bugsnag.start({
    apiKey: PetRescue.env.bugsnag.public_key,
    releaseStage: PetRescue.env.environment,
    enabledReleaseStages: ['production', 'staging'],
    appType: 'client',
    redactedKeys: [
      'access_token', // exact match: "access_token"
      /^password$/i, // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
      /^cc_/, // prefix match: "cc_number" "cc_cvv" "cc_expiry"
    ],
    onError: event => optOutOfBugsnagError(event),
  })
}

export function notifyBugsnag(error) {
  if (PetRescue.env.environment !== 'development') {
    Bugsnag.notify(error)
  } else {
    console.error(`Sending faux bugsnag error: ${error}`)
  }
}

function optOutOfBugsnagError(event) {
  // if the origional error is not actually an error type, return false
  if (typeof event.originalError !== 'object' || event.originalError instanceof Error === false) {
    return false
  }

  if (hasMatchInStackTrace(event, denyList)) return false
  return true
}

function hasMatchInStackTrace(event, regexList) {
  // check the origional error
  const origionalError = event.originalError.toString()
  for (const matcher of regexList) {
    if (matcher.test(origionalError)) return true
    // if the browser supports the stack property - check that too
    if (typeof origionalError.stack === 'string' && matcher.test(origionalError.stack)) {
      return true
    }
  }

  // check the error object if the above yeilds no results
  const error = event.errors[0]
  if (error && error.stacktrace.length) {
    for (const frame of error.stacktrace) {
      for (const matcher of regexList) {
        if (matcher.test(`${frame.method} ${frame.file}`)) return true
      }
    }
  }

  return false
}
