import $ from 'cash-dom'
import { requestNative } from 'js/lib/api/api-request'
import { notifyBugsnag } from 'js/lib/bugsnag'

const $link = $('.js-c-case-helpscout-link')
const $remove = $('.js-c-case-helpscout-remove')
const $create = $('.js-c-case-helpscout-create')
const $update = $('.js-c-case-helpscout-update')

const disabledClasses = 'tw-cursor-not-allowed tw-opacity-25'

$link.each(fetchHelpscoutLink)

$remove.each((_, link) =>
  createLinkEventHandler(
    link,
    () =>
      window.confirm(
        'Are you sure you want to remove the connection between Help Scout and this case? The Help Scout conversation won’t be deleted, but this case will no longer be linked to it.'
      ),
    'There was an error resetting the connection between Help Scout and this case'
  )
)

$create.each((_, link) =>
  createLinkEventHandler(
    link,
    () => window.confirm('Would you like to create a Help Scout conversation for this case?'),
    'There was an error creating a Help Scout conversation for this case'
  )
)

$update.each((_, link) =>
  createLinkEventHandler(
    link,
    () => {
      const inputUrl = window.prompt('What is the URL of the Help Scout conversation you would like to connect to?')

      // Validate URL format
      if (!inputUrl || !inputUrl.startsWith('https://secure.helpscout.net/conversation/')) {
        alert('Invalid Help Scout URL. Please enter a valid conversation link.')
        return null
      }

      return inputUrl
    },
    'There was an error linking this case to the Help Scout URL you provided'
  )
)

async function fetchHelpscoutLink(_, link) {
  const $link = $(link)
  const url = $link.data('href')

  if (!url) {
    const errorMessage = 'No URL exists to fetch data for HelpScout link';
    console.error(errorMessage);
    notifyBugsnag(new Error(errorMessage));
    
    return
  }

  try {
    const response = await requestNative({ url, type: 'GET' })

    if (!response || !response.href) {
      throw new Error('Invalid response structure. Issues storing the URL.')
    }

    // Ensure the correct link is set
    $link.removeClass(disabledClasses).attr({ href: response.href, target: '_blank' }).text('Help Scout')
  } catch (error) {
    console.error(error)
    $link.hide().after(
      $(`<p class="tw-text-gray-400 !tw-m-0 tw-text-sm tw-font-medium">Error fetching Help Scout URL</p>`)
    )
  }
}

function createLinkEventHandler(link, getConfirmation, errorText) {
  const $link = $(link)
  const linkText = $link.text()
  const url = $link.data('href')

  let working = false

  $link.attr('href', url)

  $link.on('click', async event => {
    event.preventDefault()

    const confirmation = getConfirmation()

    if (working === true || !confirmation) return
    working = true

    $link.addClass(disabledClasses).text('Working...')

    try {
      await requestNative({ url, data: { confirmation } })
      window.location.reload()
    } catch (error) {
      window.alert(errorText)
      $link.removeClass(disabledClasses).text(linkText)
      working = false
    }
  })
}