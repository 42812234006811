import { createAnalyticsGroup } from 'js/lib/analytics'

const link = document.querySelectorAll('.js-listing-details-tracking')

if (link.length > 0) {
  const track = createAnalyticsGroup('listing-details')
  
  link.forEach(link => {
    
    if (link) {
      const label = link.getAttribute('data-label') || link.textContent.trim() || 'unknown link'
      link.addEventListener('click', () => {
        track('click', label)
      })
    }
  })
}
