import formDataEntries from 'form-data-entries'
// import isEmail from 'validator/lib/isEmail'
import { register } from 'js/lib/create-component'
import { ValidationError } from 'js/lib/errors'
import I18n from 'js/lib/i18n.js.erb'
import { initializePaypal, getPaypalPaymentToken } from 'js/lib/payments/paypal-service'
import { initializePinPayment, handlePinPayment } from 'components/payments/pin/card_entry/component'
import { useValidation } from 'js/lib/components/use-validation'
import { createFlash } from 'components/flash/component'
import { trackDonationIntentChange } from 'js/lib/analytics'

// need a better way of:
//  a) passing state from server to client - maybe a 'client_state' helper in the application controller to collect this

// At the moment state is passed directly from the server as a JSON string,
// but it should probably be passed via a better means - this is inefficient and
// feels a bit hack

register('paymentForm', function (stateJSON) {
  const stateData = JSON.parse(stateJSON)

  const donationAmountJson = document.querySelector('.js-c-donation-amount')?.dataset?.donationAmounts
  const donationAmountData = donationAmountJson ? JSON.parse(donationAmountJson) : {}

  // We have to run the initialization code in a alpine x-init directive because we need access to the alpine components
  // to initialize some of the libraries.
  function init() {
    return async () => {
      // determine if there is a custom amount or cover fee and apply that state. We need to do it this way because
      // we need to allow those inputs to register that there are custom amounts before we select them
      const { coverTransactionFee, customAmount, paymentAmount } = isCustomAmountOrCoverFee({
        initialAmount: stateData.payment_amount,
        frequency: stateData.payment_frequency,
        paymentAmounts: donationAmountData,
      })

      this.coverTransactionFee = coverTransactionFee
      this.customAmount = customAmount
      this.paymentAmount = paymentAmount

      await initialize({
        paymentType: stateData.payment_type || 'donation',
        acceptCard: stateData.accept_card,
        acceptPaypal: stateData.accept_paypal,
        getPaymentFormData: () => serializeFormData(this.$refs.form),
        getPayPalPaymentButton: () => this.$refs.paypalButton,
        stateData,
      })

      this.initialized = true
    }
  }

  function validateWithFormData() {
    const formData = serializeFormData(this.$refs.form)
    return validatePayment(stateData.validation_url, formData)
  }

  /*
    Paypal and Pin have slightly different apis and must be checked in different ways. These handle functions give us a common interface to handle those
    each service. The flow basically goes: validate the payment and form inputs on our side -> fetch the "token" from the payment service -> if there is an
    error, set the validation error.
  */

  async function handleSubmitByPin() {
    const { token, validationErrors } = await handlePinPayment(() => validateWithFormData.call(this))
    if (validationErrors) {
      this.validationErrors = validationErrors
      return false
    }
    this.paymentToken = token
    return true
  }

  // Paypal is has blocking in built so don't need recaptcha
  async function handlePayPalPayment() {
    try {
      await validateWithFormData.call(this)
      const { paypalData, paymentData } = await getPaypalPaymentToken()

      // TODO: not sure why these values are arranged like this - logic copied from old payments-new.js file
      this.paypalPaymentId = !paypalData.payerID ? paypalData.orderID : paymentData.id
      this.paymentToken = paymentData.token
      this.paypalSubscriptionRequestedStartsAt = paymentData.subscription_starts_at
      if (paypalData.payerID) this.paypalPayerId = paypalData.payerID

      return true
    } catch (error) {
      if (ValidationError.isValidationError(error)) {
        this.validationErrors = error.detail
        return false
      }
      throw error
    }
  }

  async function validateDonor() {
    const formData = serializeFormData(this.$refs.form)
    try {
      // eslint-disable-next-line no-undef
      const response = await fetch('/donor_validations/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({
          email: formData['payment[email]'],
          phone: formData['payment[telephone_number]'],
          first_name: formData['payment[first_name]'],
          last_name: formData['payment[last_name]'],
        }),
      })
      const data = await response.json()
      return data.isAllowed
    } catch (error) {
      console.error('Validation error:', error)
      return false
    }
  }

  async function handleSubmitWithValidation() {
    const isDonorValid = await validateDonor.call(this)
    if (!isDonorValid) {
      createFlash('alert', I18n.t('payments.validation.cannot_process_transaction'))
      this.isSubmitting = false
      return
    }

    handleSubmit.call(this)
  }

  async function handleSubmit() {
    if (this.isSubmitting === true) return
    this.isSubmitting = true
    this.validationErrors = {}

    const form = this.$refs.form
    const paymentMethod = this.paymentMethod === 'card' ? handleSubmitByPin : handlePayPalPayment
    const paymentSetupSuccess = await paymentMethod.call(this) // we have to use call() so fn is in alpine scope

    if (paymentSetupSuccess) {
      // Submit the form after alpine has updated everything.
      this.$nextTick(() => form.submit()) // Note that form.submit does not raise a submit event so it will skip this handler
    } else {
      this.isSubmitting = false
      this.$nextTick(() => this.scrollToErrors())
    }
  }

  function handleSelectAmountButton(amount, frequency) {
    return {
      ['x-on:change']() {
        this.paymentAmount = amount
        document.getElementById('js-custom_amount--input').value = ''
        this.customAmount = false

        this.trackPaymentInfoChange()
      },
      ['x-bind:checked']() {
        return this.paymentAmount === amount && this.paymentFrequency === frequency && this.customAmount === false
      },
    }
  }

  function handleSetCustomAmountInput() {
    return {
      ['x-on:change'](event) {
        const amountSubunit = Math.round(parseFloat(event.target.value, 10) * 100)
        this.paymentAmount = typeof amountSubunit === 'number' && !isNaN(amountSubunit) ? amountSubunit : ''
        this.customAmount = true

        // mega hack move (not sure why this isn't working in alpine - the checked should be resolved in the
        // handleSelectAmountButton part but that isn't working):
        document.querySelectorAll('.js-c-donation-amount__radio-input').forEach(el => (el.checked = false))

        this.trackPaymentInfoChange()
      },
      // If an amount is defined in the URL, for example: ?amount=13
      // On page load, the custom amount option will be active
      // the following will bind 13 to the input field.
      // Else the input field will be blank
      ['x-bind:value']() {
        if (this.customAmount && this.paymentAmount > 0) {
          return getHumanNumberFromSubunit(this.paymentAmount)
        } else {
          return ''
        }
      },
    }
  }

  function handlePaymentFrequencyChange(event) {
    const paymentFrequency = event.target.value

    // Handle show/hide amounts containers
    // First hide all the amount containers
    const containers = document.querySelectorAll('.c-donation-amounts-container')
    containers.forEach(container => {
      container.classList.add('hidden-visually')
    })
    // Second show the container corresponding to the selected paymentFrequency
    const selectedContainer = document.querySelector(`.${paymentFrequency}-amounts-container`)
    if (selectedContainer) {
      selectedContainer.classList.remove('hidden-visually')
    }

    // If there is a custom amount already set, ignore pre-selection of 2nd last or last amounts
    if (this.customAmount) return

    const initialAmountContainer = document.querySelector('.js-hidden_initial_donation_amount')
    const initialAmount = initialAmountContainer ? parseFloat(initialAmountContainer.dataset.initialAmount) : null

    if (!isNaN(initialAmount)) {
      const amountInput = selectedContainer.querySelector(`[value="${initialAmount}"]`)
      if (amountInput) {
        amountInput.checked = true
        this.paymentAmount = initialAmount
        return
      }
    }

    // Pre-select the second last or last amount input based on our preferred condition
    const amountInputs = selectedContainer.querySelectorAll('[name="amount"]')
    if (amountInputs.length >= 2) {
      amountInputs[amountInputs.length - 2].checked = true
      this.paymentAmount = amountInputs[amountInputs.length - 2].value
    } else if (amountInputs.length >= 1) {
      amountInputs[amountInputs.length - 1].checked = true
      this.paymentAmount = amountInputs[amountInputs.length - 1].value
    }

    this.trackPaymentInfoChange()
  }

  function handleMethodChange(paymentMethod) {
    this.paypalPaymentId = ''
    this.paypalPayerId = ''
    this.paypalSubscriptionRequestedStartsAt = ''
    this.paymentToken = ''
    this.paymentMethod = paymentMethod
  }

  function trackPaymentInfoChange() {
    // Only donations are supported
    if (stateData.payment_type !== 'donation') return
    trackDonationIntentChange({
      pageName: stateData.conversion_page_name,
      frequency: this.paymentFrequency,
      value: (this.paymentAmount / 100).toFixed(2),
    })
  }

  return {
    ...useValidation(),
    initialized: false,
    paymentMethod: stateData.payment_method,
    paymentAmount: stateData.payment_amount,
    paymentFrequency: stateData.payment_frequency,
    paymentReference: stateData.payment_reference,
    paymentIsGift: stateData.payment_is_gift,
    donationAmounts: JSON.stringify(donationAmountData),
    customAmount: false,
    coverTransactionFee: false,
    isSubmitting: false,
    // paypal fields
    paypalPaymentId: '',
    paypalPayerId: '',
    paypalSubscriptionRequestedStartsAt: '',
    // payment fields
    paymentToken: '',
    // Computed values
    get finalAmount() {
      const amount = this.coverTransactionFee ? parseInt(this.paymentAmount) + coverFee(this.paymentAmount) : this.paymentAmount
      return getHumanNumberFromSubunit(amount)
    },
    get amountText() {
      // turn into human readable currency
      const initialAmount = parseFloat(this.paymentAmount >= 0 ? this.paymentAmount : 0)
      const netAmount = this.coverTransactionFee ? initialAmount + coverFee(initialAmount) : initialAmount
      const amount = getHumanNumberFromSubunit(netAmount).toLocaleString()

      return I18n.t(`payments.payment_form.component.amount.${this.paymentFrequency}`, { amount })
    },
    get feeCoverText() {
      const amount = getHumanNumberFromSubunit(coverFee(this.paymentAmount || 0))
      return I18n.t('payments.payment_form_checks.component.accept_fees.label', { amount })
    },
    get paymentMethodIsPayPal() {
      return this.paymentMethod === 'paypal'
    },
    get paymentMethodIsCard() {
      return this.paymentMethod === 'card'
    },
    get paymentAmountIsInvalid() {
      return this.paymentAmount < 200
    },
    // handlers
    handleSubmit: handleSubmitWithValidation,
    handleMethodChange,
    handleSelectAmountButton,
    handleSetCustomAmountInput,
    handlePaymentFrequencyChange,
    trackPaymentInfoChange,
    init,
  }
})

// Initializers

async function initialize(options) {
  initializeFormFields()

  const { getPaymentFormData, getPayPalPaymentButton, acceptCard, acceptPaypal } = options

  const initPaypal = acceptPaypal ? initializePaypal(getPayPalPaymentButton(), getPaymentFormData) : Promise.resolve()
  const initPin = acceptCard ? initializePinPayment() : Promise.resolve()

  await Promise.all([initPaypal, initPin])
}

// We should be able to remove this by building our own phone number field
function initializeFormFields() {
  const $form = $('.payments__new--payment_form')
  const $paymentTelephoneType = $form.find('input[name="payment[telephone_number_type]"]')
  const $paymentTelephone = $('#payment_telephone_number')

  $paymentTelephone.intlTelInput({
    initialCountry: 'AU',
    formatOnInit: true,
    hiddenInput: $paymentTelephone.prop('name'),
    autoPlaceholder: 'aggressive',
    customPlaceholder: function () {
      return I18n.t('intl_tel_input.placeholder')
    },
  })

  $paymentTelephone.on('propertychange input', function () {
    var inputName = $(this).prop('name')
    var $hiddenInput = $form.find('input[name="' + inputName + '"]:hidden')
    var numberTypeId = $paymentTelephone.intlTelInput('getNumberType')
    var numberTypes = Object.keys(intlTelInputUtils.numberType)
    var numberTypeIds = Object.values(intlTelInputUtils.numberType)
    var index = numberTypeIds.indexOf(numberTypeId)

    $hiddenInput.val($paymentTelephone.intlTelInput('getNumber'))
    $paymentTelephoneType.val(numberTypes[index] || 'UNKNOWN')
  })
}

function isCustomAmountOrCoverFee({ initialAmount, frequency, paymentAmounts }) {
  const amount = getInitialDonationAmount({ initialAmount, frequency, paymentAmounts })
  const amounts = Object.values(paymentAmounts[frequency] || {})

  for (const value of amounts) {
    const valueWithCharge = value + withCharge(value)
    if (amount === valueWithCharge) {
      return { paymentAmount: value, customAmount: false, coverTransactionFee: true }
    } else if (amount === value) {
      return { paymentAmount: value, customAmount: false, coverTransactionFee: false }
    }
  }
  return { paymentAmount: amount, customAmount: true, coverTransactionFee: false }
}

function getInitialDonationAmount({ initialAmount, frequency, paymentAmounts }) {
  if (initialAmount) return initialAmount
  if (paymentAmounts) {
    const values = Object.values(paymentAmounts[frequency] || {})
    const secondLastAmountvalue = values[values.length - 2] || values[values.length - 1]

    if (secondLastAmountvalue) return Number(secondLastAmountvalue)
  }

  // if no amount is selected, or no custom payment amounts added, then get the value from the dom
  return Number(getDefaultPaymentAmount(paymentAmounts, frequency))
}

// validation

async function validatePayment(url, formData) {
  try {
    return await $.ajax({ url, type: 'POST', data: formData, dataType: 'json' })
  } catch (error) {
    const response = error.responseJSON
    const fieldErrors = {}
    if (!response.data || !response.data.errors) {
      createFlash('notice', 'Error validating payment')
      throw error
    }
    for (const key in response.data.errors) {
      fieldErrors[key] = response.data.errors[key].join(', ')
    }
    console.log(fieldErrors)
    throw new ValidationError('Payment validation error', fieldErrors)
  }
}

// helpers

function getDefaultPaymentAmount(paymentAmounts, frequency) {
  const values = Object.values(paymentAmounts[frequency] || {})

  if (values.length >= 2) {
    return values[values.length - 2]
  } else if (values.length >= 1) {
    return values[values.length - 1]
  }
}

function getHumanNumberFromSubunit(subunit) {
  return (Math.round((subunit / 100 + Number.EPSILON) * 100) / 100).toFixed(2)
}

function coverFee(amountCents) {
  return amountCents * 0.026 + 37
}

function serializeFormData(form) {
  const data = {}
  for (const input of formDataEntries(form)) {
    const [name, value] = input
    data[name] = value
  }
  return data
}

function withCharge(amount) {
  return Math.round(amount * 0.026 + 0.37)
}
