import { notifyBugsnag } from 'js/lib/bugsnag'

const editModal = document.querySelectorAll('.js-c-pages-rms-labels-manager--edit_label')

editModal.forEach((trigger) => {
  trigger.addEventListener('click', () => {
    const form = document.querySelector('#edit-label-modal form')

    if (!form) {
      notifyBugsnag("Missing form element")
      return
    }

    const labelId = trigger.dataset.labelId
    const labelName = trigger.dataset.labelName
    const labelColor = trigger.dataset.labelColor
    const labelType = trigger.dataset.labelType
    const groupId = trigger.dataset.groupId

    form.setAttribute('action', `/admin/groups/${groupId}/rms/labels/${labelId}`)

    form.querySelector('[name="rms_label[name]"]').value = labelName
    form.querySelector(`[name="rms_label[label_type]"][value="${labelType}"]`).checked = true
    form.querySelector('[name="rms_label[color]"]').value = labelColor
  })
})
